import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";

const LinkPostPreview = () => {
	const dummyPost = {
		title: "Example Post Title",
		description: "This is an example post description",
		image:
			"https://plus.unsplash.com/premium_photo-1669689970215-24c00ad4b60c?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Replace with an actual image URL
	};

	const twitterCardMetadata = {
		card: "summary",
		site: "@PratikMane76722",
		title: dummyPost.title,
		description: dummyPost.description,
		image: dummyPost.image,
	};

	return (
		<div>
			<Helmet>
				<meta
					name="robots"
					content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@PratikMane76722" />
				<meta name="twitter:title" content="Sample Post Title" />
				<meta
					name="twitter:description"
					content="This is a sample post description for Twitter."
				/>
				<meta
					name="twitter:image"
					content="https://www.pratititech.com/wp-content/uploads/2021/05/Home-Hero-1024x576.jpg"
				/>
				<meta property="og:title" content="Sample Post Title"></meta>
				<meta
					property="og:description"
					content="This is a sample post description for Twitter."
				></meta>
				<meta
					property="og:image"
					content="https://www.pratititech.com/wp-content/uploads/2021/05/Home-Hero-1024x576.jpg"
				/>
				<meta property="og:url" content="https://test.44ti.me/#/link-preview" />
				<meta
					property="og:image:secure_url"
					content="https://www.pratititech.com/wp-content/uploads/2021/05/Home-Hero-1024x576.jpg"
				/>
				<meta property="og:image:type" content="image/jpeg" />
				<meta property="og:type" content="website" />
			</Helmet>

			<Grid container xs={12}>
				<Grid item xs={12}>
					<img
						width="300px"
						src="https://www.pratititech.com/wp-content/uploads/2021/05/Home-Hero-1024x576.jpg"
						alt="Post Image"
					/>
				</Grid>
				<Grid item xs={6}>
					{twitterCardMetadata.description}
				</Grid>

				{/* <TwitterShareButton
					// @ts-ignore
					options={{
						size: "large",
						text: "#reactjs is awesome",
						via: "saurabhnemade",
					}}
					placeholder="Loading"
					url="https://plus.unsplash.com/premium_photo-1669689970215-24c00ad4b60c?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3"
				>
					<TwitterIcon size={32} round />
				</TwitterShareButton> */}
			</Grid>
		</div>
	);
};

export default LinkPostPreview;
